import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";


class RoleService {
    async getRoles(){
        let roles = await axiosService.get(CONSTANTS.APIURL + "roles");
        return roles;
    }
}
export default new RoleService();